/*! modernizr 3.6.0 (Custom Build) | MIT *
   * https://modernizr.com/download/?-webgl-setclasses !*/
!function(e,n,t){function o(e,n){return typeof e===n}function s(){var e,n,t,s,a,i,f;for(var c in l)if(l.hasOwnProperty(c)){if(e=[],n=l[c],n.name&&(e.push(n.name.toLowerCase()),n.options&&n.options.aliases&&n.options.aliases.length))for(t=0;t<n.options.aliases.length;t++)e.push(n.options.aliases[t].toLowerCase());for(s=o(n.fn,"function")?n.fn():n.fn,a=0;a<e.length;a++)i=e[a],f=i.split("."),1===f.length?Modernizr[f[0]]=s:(!Modernizr[f[0]]||Modernizr[f[0]]instanceof Boolean||(Modernizr[f[0]]=new Boolean(Modernizr[f[0]])),Modernizr[f[0]][f[1]]=s),r.push((s?"":"no-")+f.join("-"))}}function a(e){var n=c.className,t=Modernizr._config.classPrefix||"";if(u&&(n=n.baseVal),Modernizr._config.enableJSClass){var o=new RegExp("(^|\\s)"+t+"no-js(\\s|$)");n=n.replace(o,"$1"+t+"js$2")}Modernizr._config.enableClasses&&(n+=" "+t+e.join(" "+t),u?c.className.baseVal=n:c.className=n)}function i(){return"function"!=typeof n.createElement?n.createElement(arguments[0]):u?n.createElementNS.call(n,"http://www.w3.org/2000/svg",arguments[0]):n.createElement.apply(n,arguments)}var r=[],l=[],f={_version:"3.6.0",_config:{classPrefix:"",enableClasses:!0,enableJSClass:!0,usePrefixes:!0},_q:[],on:function(e,n){var t=this;setTimeout(function(){n(t[e])},0)},addTest:function(e,n,t){l.push({name:e,fn:n,options:t})},addAsyncTest:function(e){l.push({name:null,fn:e})}},Modernizr=function(){};Modernizr.prototype=f,Modernizr=new Modernizr;var c=n.documentElement,u="svg"===c.nodeName.toLowerCase();Modernizr.addTest("webgl",function(){var n=i("canvas"),t="probablySupportsContext"in n?"probablySupportsContext":"supportsContext";return t in n?n[t]("webgl")||n[t]("experimental-webgl"):"WebGLRenderingContext"in e}),s(),a(r),delete f.addTest,delete f.addAsyncTest;for(var p=0;p<Modernizr._q.length;p++)Modernizr._q[p]();e.Modernizr=Modernizr}(window,document);

let body = document.querySelector('body');

window.addEventListener("DOMContentLoaded", () => {
    body.classList.remove('no-js');
    if ( document.documentElement.scrollTop == 0) {
        body.classList.add('top');
        document.body.classList.remove("small-logo");
    }
    if (document.body.scrollTop > window.innerHeight * 2 || document.documentElement.scrollTop > window.innerHeight * 2) {
        body.classList.add('no-logo-animation');
    }
});


var buttons = document.querySelectorAll( ".scroll-to-btn" );
for ( var btnCounter = 0; btnCounter < buttons.length; btnCounter++)
{
    buttons[btnCounter].addEventListener("click", function(){
        var target = this.dataset.target;
        document.getElementById(target).scrollIntoView();
    });
}



var buttons = document.querySelectorAll( ".scroll-to-prj" );
for ( var btnCounter = 0; btnCounter < buttons.length; btnCounter++)
{
    buttons[btnCounter].addEventListener("click", function(){
        var target = '#'+this.dataset.target;
        if(this.classList.contains('prelast')) {
            var offset = 0;
        } else {
            var offset = 0;
        }

        window.scrollTo({
            behavior: 'smooth',
            top:
                document.querySelector(target).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top +
                offset,
        })
    });
}


document.addEventListener('DOMContentLoaded', (event) => {
    var buttonsDown = document.querySelectorAll( ".scroll-down-btn" );

    for ( var btnDownCounter = 0; btnDownCounter < buttonsDown.length; btnDownCounter++)
    {

        buttonsDown[btnDownCounter].addEventListener("click", function(){
            window.scrollBy(0, window.innerHeight );
        });
    }
    var buttonsUp = document.querySelectorAll( ".scroll-up-btn" );
    for ( var btnUpCounter = 0; btnUpCounter < buttonsUp.length; btnUpCounter++)
    {

        buttonsUp[btnUpCounter].addEventListener("click", function(){
            window.scrollBy(0, window.innerHeight);
        });
    }
})

/* Add Browser Class to HTML */
var browser_name = '';
isIE = /*@cc_on!@*/false || !!document.documentMode;
isEdge = !isIE && !!window.StyleMedia;
if (navigator.userAgent.indexOf("Chrome") != -1 && !isEdge) {
    browser_name = 'chrome';
} else if (navigator.userAgent.indexOf("Safari") != -1 && !isEdge) {
    browser_name = 'safari';
} else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser_name = 'firefox';
} else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
{
    browser_name = 'ie';
} else if (isEdge) {
    browser_name = 'edge';
} else {
    browser_name = 'other-browser';
}

let html = document.querySelector('html');

html.classList.add(browser_name);
/* End */

const btnNav = document.querySelector(".navbar-toggler");
const burger = document.querySelector("#nav-icon3");
const navbar = document.querySelector(".navbar");


var lastScrollTop = 0;

document.addEventListener('scroll', (e) => {

    var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

    if (st > lastScrollTop){
        document.body.classList.remove("up");
    } else {
        document.body.classList.add("up");
    }

    if (window.scrollY == 0) {
        body.classList.add('top');
        document.body.classList.remove("small-logo");
    } else {
        body.classList.remove('top');
        if (st > lastScrollTop){
            setTimeout(() => {
                if(!body.classList.contains('top')) {
                    document.body.classList.add("small-logo");
                }
            }, "1000")
        }
    }

    if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        document.querySelector(".back-to-top").style.opacity = "1";
    } else {
        document.querySelector(".back-to-top").style.opacity = "0";
    }
    if (document.body.scrollTop > window.innerHeight * 2 || document.documentElement.scrollTop > window.innerHeight * 2) {
        body.classList.add('no-logo-animation');
    } else {
        body.classList.remove('no-logo-animation');
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
});
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

document.querySelector(".back-to-top").addEventListener("click", (e) => {
    topFunction();
});

/* End */


